/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {

  .h-screen {
    height: 100vh;
    max-height: 100vh;
    max-height: -webkit-fill-available;
    max-height: -moz-available;
  }


  @font-face {
    font-family: "Montserrat";
    src: url('./fonts/Montserrat/Montserrat-Black.ttf') format('truetype');
    font-weight: 900;
  }

  @font-face {
    font-family: "Montserrat";
    src: url('./fonts/Montserrat/Montserrat-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
  }

  @font-face {
    font-family: "Montserrat";
    src: url('./fonts/Montserrat/Montserrat-ExtraBold.ttf') format('truetype');
    font-weight: 800;
  }

  @font-face {
    font-family: "Montserrat";
    src: url('./fonts/Montserrat/Montserrat-ExtraBoldItalic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
  }

  @font-face {
    font-family: "Montserrat";
    src: url('./fonts/Montserrat/Montserrat-Bold.ttf') format('truetype');
    font-weight: 700;
  }

  @font-face {
    font-family: "Montserrat";
    src: url('./fonts/Montserrat/Montserrat-BoldItalic.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
  }

  @font-face {
    font-family: "Montserrat";
    src: url('./fonts/Montserrat/Montserrat-SemiBold.ttf') format('truetype');
    font-weight: 600;
  }

  @font-face {
    font-family: "Montserrat";
    src: url('./fonts/Montserrat/Montserrat-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
  }

  @font-face {
    font-family: "Montserrat";
    src: url('./fonts/Montserrat/Montserrat-Medium.ttf') format('truetype');
    font-weight: 500;
  }

  @font-face {
    font-family: "Montserrat";
    src: url('./fonts/Montserrat/Montserrat-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
  }

  @font-face {
    font-family: "Montserrat";
    src: url('./fonts/Montserrat/Montserrat-Regular.ttf') format('truetype');
    font-weight: 400;
  }

  @font-face {
    font-family: "Montserrat";
    src: url('./fonts/Montserrat/Montserrat-Italic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
  }

  @font-face {
    font-family: "Montserrat";
    src: url('./fonts/Montserrat/Montserrat-Light.ttf') format('truetype');
    font-weight: 300;
  }

  @font-face {
    font-family: "Montserrat";
    src: url('./fonts/Montserrat/Montserrat-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
  }

  @font-face {
    font-family: "Montserrat";
    src: url('./fonts/Montserrat/Montserrat-ExtraLightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
  }

  @font-face {
    font-family: "Montserrat";
    src: url('./fonts/Montserrat/Montserrat-ExtraLight.ttf') format('truetype');
    font-weight: 200;
  }

  @font-face {
    font-family: "Montserrat";
    src: url('./fonts/Montserrat/Montserrat-Thin.ttf') format('truetype');
    font-weight: 100;
  }

  @font-face {
    font-family: "Montserrat";
    src: url('./fonts/Montserrat/Montserrat-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
  }

  @font-face {
    font-family: "Snell";
    src: url('./fonts/Snell/SnellRoundhand-BlackScript.otf') format('opentype');
    font-weight: 900;
  }

  @font-face {
    font-family: "Snell";
    src: url('./fonts/Snell/SnellBT-Bold.otf') format('opentype');
    font-weight: 700;
  }

  @font-face {
    font-family: "Snell";
    src: url('./fonts/Snell/SnellBT-Regular.otf') format('opentype');
    font-weight: 400;
  }

  @font-face {
    font-family: "Snell";
    src: url('./fonts/Snell/SnellBT-Regular.otf') format('opentype');
    font-weight: 500;
  }
}


html,
body {
  @apply h-screen w-full overflow-hidden
}
